import { Grid, Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import { useRouterStore } from 'mobx-state-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DateRange from './DateRange';
import DropDown from './DropDown';

const LmsToolbar = ({ title, orgType, profType, dateType }) => {
  const { t } = useTranslation();
  const store = useStore();
  const routerStore = useRouterStore();

  const urlQueries = routerStore.routerState.queryParams;

  const handleChangeOrganisation = (event, newValue) => {
    const { queryParams } = routerStore.routerState; // Get the current query parameters
    const updatedParams = { ...queryParams, org_id: newValue?.organisation_id.toString() }; // Merge with new parameters
    routerStore.goTo(routerStore.routerState.routeName, { queryParams: updatedParams }); // Update the URL
  };
  const handleChangeProfession = (event, newValue) => {
    const { queryParams } = routerStore.routerState; // Get the current query parameters
    const updatedParams = { ...queryParams, profession: newValue?.profession_id.toString() }; // Merge with new parameters
    routerStore.goTo(routerStore.routerState.routeName, { queryParams: updatedParams }); // Update the URL
  };
  const handleRangeDate = ({ from, to }) => {
    const startdate = from ? format(from, 'yyyy-MM-dd') : undefined;
    const enddate = to ? format(to, 'yyyy-MM-dd') : undefined;
    const { queryParams, params } = routerStore.routerState; // Get the current query parameters
    const updatedParams = { ...queryParams, startdate, enddate }; // Merge with new parameters
    routerStore.goTo(routerStore.routerState.routeName, { queryParams: updatedParams }, { ...params }); // Update the URL
  };

  return (
    <Box sx={{ borderBottom: '20px solid #f5f7fe', paddingBottom: '10px', paddingTop: '0px' }}>
      <Grid
        id="lmsToolbar"
        container
        alignItems="center"
        justifyContent="center"
        bgcolor="white"
        borderRadius={2}
        rowGap={2}
        columnGap={1}
        pt={2}
        pb={1}
        px={2}
        flexWrap="wrap"
      >
        {title && (
          <Grid item mr="auto" style={{ display: 'none' }}>
            <Typography variant="h2" mr="auto">
              {title}
            </Typography>
          </Grid>
        )}
        {orgType === 1 && !store.showMenuAssistant && (
          <Grid item xs={12} lg={3}>
            <DropDown
              handleChange={handleChangeOrganisation}
              label={t('lms.toolBar.dropDown.organisation.label')}
              placeholder={t('lms.toolBar.dropDown.organisation.placeholder')}
              options={store.lmsAccess?.organisations}
              value={urlQueries?.org_id || null}
            />
          </Grid>
        )}
        {profType === 1 && !store.showMenuAssistant && (
          <Grid item xs={12} lg={3}>
            <DropDown
              handleChange={handleChangeProfession}
              label={t('lms.toolBar.dropDown.profession.label')}
              placeholder={t('lms.toolBar.dropDown.profession.placeholder')}
              options={store.lmsAccess?.professions}
              value={urlQueries?.profession || null}
            />
          </Grid>
        )}
        {dateType === 1 && (
          <Grid item xs={12} lg={3}>
            <DateRange handleRangeDate={handleRangeDate} from={urlQueries?.startdate} to={urlQueries?.enddate} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default observer(LmsToolbar);

LmsToolbar.propTypes = {
  title: PropTypes.string,
  orgType: PropTypes.number,
  profType: PropTypes.number,
  dateType: PropTypes.number
};
