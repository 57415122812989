/* Core */
import { useContext, useRef } from 'react';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';

/* MUI */
import { Box, Button, ButtonGroup, IconButton, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GridOnIcon from '@mui/icons-material/GridViewSharp';
import ListIcon from '@mui/icons-material/List';
import ListAlt from '@mui/icons-material/ListAlt';
import { AccordionComponent } from 'components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import Filter from './RightSideBar/FilterContainer';

const CourseView = () => {
  const store = useContext(Store);
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const isSpecialAccessView = store.routerState.routeName.includes('specialAccess');

  const selectedIndex = store.courseoverview - 1;

  const options = [
    { id: 'view-list-condensed', icon: <ListIcon color="primary" /> },
    { id: 'view-grid', icon: <GridOnIcon color="primary" /> },
    { id: 'view-table', icon: <ListAlt color="primary" /> }
  ];
  const handleMenuItemClick = (index) => {
    store.setStoreValue('courseoverview', index + 1);
  };

  const handleClick = () => {
    store.patchData(store.hidden, {
      id: store.hidden.uuid,
      type: 'hidden_courses',
      relationships: {
        hidden_courses: {
          data: []
        }
      }
    });
  };

  return (
    <Box display="flex" gap="13px" justifyContent="flex-end" position="relative">
      {store.courseHeaderTabs === 'Started' && !isSpecialAccessView && (
        <Box className="show_hidden_box">
          <Tooltip title={t('courseoverview.showHidden', 'show hidden!')}>
            <span>
              <IconButton
                className="show_hidden"
                sx={{ backgroundColor: 'background.main' }}
                onClick={handleClick}
                disabled={!store.hiddenIds?.length}
              >
                {store.hiddenIds?.length ? <VisibilityIcon sx={{ color: 'primary.main' }} /> : <VisibilityOffIcon sx={{ color: 'gray' }} />}
                <Typography className="visibility_text">{t('courseoverview.showHidden', 'show hidden!')}</Typography>
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
      {store.courseHeaderTabs === 'All' && !isSpecialAccessView && <Filter />}
      <Box className="course_overview_filters_grid">
        <AccordionComponent
          title={
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
              <Button data-testid="btn-menu" sx={{ backgroundColor: 'background.main' }}>
                <span data-testid={options[selectedIndex].id}>{options[selectedIndex].icon}</span>
              </Button>
            </ButtonGroup>
          }
          body={
            <MenuList id="split-button-menu" autoFocusItem variant="menu">
              {options.map((option, index) => (
                <MenuItem
                  data-testid={index !== selectedIndex && option.id}
                  key={option.id}
                  selected={index === selectedIndex}
                  onClick={() => handleMenuItemClick(index)}
                >
                  {index === selectedIndex ? '' : option.icon}
                </MenuItem>
              ))}
            </MenuList>
          }
          addIcon={<ArrowDropDownIcon color="primary" />}
          open={false}
          clickAway
        />
      </Box>
    </Box>
  );
};
export default observer(CourseView);
