import { format } from 'date-fns';
import { omitBy, isNil } from 'lodash';

function getData(url) {
  return async (query) => {
    const isOrganisation = url === 'ewapi/lms/organisation';
    const isStudent = url === 'ewapi/lms/student';
    const queryParams = omitBy(
      {
        profession: query.profession || null,
        org_id: query.orgId || null,
        startdate: query.startdate || null,
        enddate: query.enddate || null,
        page: query.page !== undefined ? query.page + 1 : null,
        amount: query.pageSize || null,
        name_or_email: query.searchByNameOrEmail || null,
        order_by: query.orderBy?.field,
        order_direction: query.orderDirection || null,
        ...query.filters.reduce(
          (acc, filter) => ({
            ...acc,
            [filter.column.field]: filter.value
          }),
          {}
        )
      },
      isNil
    );
    if (query?.status_condition) {
      queryParams.status_condition = query.status_condition;
    }
    if (query?.opencourses) {
      queryParams.opencourses = query.opencourses;
    }
    if (query?.completedcourses) {
      queryParams.completedcourses = query.completedcourses;
    }

    const params = new URLSearchParams(queryParams);
    const res = await window.authedClient.get(`${url}?${params}`);
    const result = res.data;
    //
    if (isOrganisation) {
      return {
        data: result.data.map((organisation) => ({
          ...organisation,
          customId: organisation.organisation_id // needed for the table to work
        }))
      };
    }

    if (isStudent) {
      return {
        data: result.data.map((student) => ({
          ...student,
          customId: student.uid, // needed for the table to work
          orgName: student.organisations?.map((organisation) => organisation.name).join(','),
          subscription_date_end: student.subscription_date_end ? format(new Date(student.subscription_date_end) * 1000, 'dd-MM-yyyy') : '-'
        })),
        page: queryParams.page - 1,
        totalCount: result.meta.totalRecords
      };
    }

    return {
      data: result.data.map((course) => ({
        ...course,
        customId: course.course_id, // needed for the table to work
        valid_until: course.valid_until ? format(new Date(course.valid_until) * 1000, 'dd-MM-yyyy') : '-',
        certificate_date: course.certificate_date ? format(new Date(course.certificate_date * 1000), 'dd-MM-yyyy') : '-'
      })),
      page: queryParams.page - 1,
      totalCount: result.meta.totalRecords
    };
  };
}

export function getLmsCourses(query) {
  return getData('ewapi/lms/course')(query);
}
export function getLmsCoursesCompleted(query) {
  return getData('ewapi/lms/course/completed')(query);
}
export function getLmsCoursesOpen(query) {
  return getData('ewapi/lms/course/open')(query);
}
export function getLmsOrganisations(query) {
  return getData('ewapi/lms/organisation')(query);
}
export function getLmsStudents(query) {
  return getData('ewapi/lms/student')(query);
}
export function getLmsStudentsOpen(query) {
  return getData('ewapi/lms/student')({ ...query, status_condition: '>', opencourses: '0' });
}
export function getLmsStudentsCompleted(query) {
  return getData('ewapi/lms/student')({ ...query, status_condition: '>', completedcourses: '0' });
}
