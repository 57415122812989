/* eslint-disable camelcase */
/* Core */
import { observer } from 'mobx-react';
import { useStore, useBreakpoint } from 'hooks';
import { useEffect, useState } from 'react';

/* MUI */
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

/* Helpers */
import { GetPoints } from 'helpers/Helpers';

/* Custom components */
import ErrorPage from 'pages/misc/ErrorPage';
import NewUserTerms from 'pages/mainLayout/footer/NewUserTerms';
import CourseIntroductionHeader from './components/CourseIntroduction/CourseIntroductionHeader';
import CourseIntroductionTabs from './components/CourseIntroduction/CourseIntroductionTabs';
import MobileCourseIntroductionTabs from './components/CourseIntroduction/mobile/MobileCourseIntroductionTabs';
import MobileCourseIntroductionHeader from './components/CourseIntroduction/mobile/MobileCourseIntroductionHeader';

// eslint-disable-next-line import/no-cycle
import Sidebar from './components/CourseIntroduction/SideBar';

const drupalBaseUrl = process.env.REACT_APP_JSONAPI;

/*
In summary, the CourseIntroduction component appears to be responsible for rendering the introduction and details of a course. 
It retrieves course information from the store, 
handles different breakpoints for responsive design, 
and structures the content layout, 
including tabs and a sidebar, based on the screen size. 
If there are issues with the course ID or if the course cannot be found, it renders an error page.
*/
const CourseIntroduction = () => {
  const store = useStore();
  const mobileBreakpoint = useBreakpoint();
  const [openGdpr, setOpenGdpr] = useState(false);
  const isMobile = mobileBreakpoint === 'sm' || mobileBreakpoint === 'xs';

  const oncloseGdpr = () => {
    setOpenGdpr(!openGdpr);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.scroll(0, 0);
    store.setPrevTab(store.selectedPackageId);
    if (!store?.login?.gdpr && !sessionStorage.getItem('gdpr')) {
      setOpenGdpr(true);
      store.setGdpr();
    }
  }, [store, store.params.courseId]);

  if (!store.params?.courseId) return <ErrorPage />;
  const course = store.findCourseByNid(Number(store.params.courseId));
  const selectedPackage = store?.baseProduct?.packages?.find((packag) => packag.nid === store.selectedPackageId);
  const logo = selectedPackage?.accreditation?.accr_logo || '';
  const isAccreditated = selectedPackage?.accreditation !== null;
  if (!course) return <ErrorPage />;
  // Course details
  const {
    course_category,
    course_category2,
    course_extra_points,
    course_id,
    course_points,
    course_extra_points_desc,
    course_status,
    course_title,
    course_uuid,
    coursephoto_url,
    headerphoto_url,
    lc_author_id,
    // lc_course_header_photo,
    lc_extra_label,
    lc_goals,
    lc_information,
    lc_podcast_html,
    lc_preview_video,
    lc_reviewer_id,
    valid_period_end,
    lessons
  } = course;

  const globalFallbackBanner = '/profBanners/elearn.jpg';
  const _introBanner = headerphoto_url?.replace(/%20/g, '%2520') || store.profession?.default_profession_banner;
  const introductionBanner = _introBanner ? `${drupalBaseUrl}${_introBanner}` : globalFallbackBanner;

  const courseImage = coursephoto_url?.replace(/%20/g, '%2520') || store.defaultCourseGridImage;

  const isDrawerOpen = mobileBreakpoint === 'md' && !store.minDrawer;
  const isCourseExpiring = store.courses.isCourseExpiring(course_id);

  const MobileIntroHead = (
    <MobileCourseIntroductionHeader
      authors={lc_author_id}
      title={course_title}
      subTitle={lc_extra_label}
      badges={course_category2}
      id={course_id}
      uuid={course_uuid}
      lesson={lessons}
      chip={course_status}
      video={lc_preview_video}
      isAccredited={isAccreditated}
      accreditation={GetPoints(course_points, course_extra_points, course_extra_points_desc)}
      duration={course_points}
      extraPoints={course_extra_points}
      extraPointsDescr={course_extra_points_desc}
      lessons={lessons}
      endDate={valid_period_end}
      courseStatus={course_status}
      isCourseExpiring={isCourseExpiring}
    />
  );

  const MobileIntroBody = (
    <Grid item xs={12} sm={12} md={8}>
      <Box className="course_introduction_mobile">
        <MobileCourseIntroductionTabs
          authors={lc_author_id}
          goals={lc_goals}
          description={lc_information}
          podcast={lc_podcast_html}
          reviewers={lc_reviewer_id}
        />
      </Box>
    </Grid>
  );

  const DesktopIntroHead = (
    <CourseIntroductionHeader
      image={introductionBanner}
      authors={lc_author_id}
      title={course_title}
      subTitle={lc_extra_label}
      badges={course_category2}
      id={course_id}
      uuid={course_uuid}
      lesson={lessons}
      chip={course_status}
      isCourseExpiring={isCourseExpiring}
    />
  );

  const DesktopIntroBody = (
    <>
      <Grid item xs={12} sm={7} md={8}>
        <CourseIntroductionTabs
          authors={lc_author_id}
          goals={lc_goals}
          description={lc_information}
          video={lc_preview_video}
          podcast={lc_podcast_html}
          category={course_category.map((category) => category.name)[0]}
          courseId={course_id}
          reviewers={lc_reviewer_id}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={4} className="sidebar_container">
        <Sidebar
          isAccredited={isAccreditated}
          accreditation={GetPoints(course_points, course_extra_points, course_extra_points_desc)}
          duration={course_points}
          extraPoints={course_extra_points}
          extraPointsDescr={course_extra_points_desc}
          id={course_id}
          image={courseImage && `${drupalBaseUrl}${courseImage}`}
          title={course_title}
          lessons={lessons}
          uuid={course_uuid}
          logo={logo}
          endDate={valid_period_end}
          courseStatus={course_status}
        />
      </Grid>
    </>
  );

  return (
    <>
      <div className="course_introduction">
        {!isMobile ? DesktopIntroHead : MobileIntroHead}
        <Box className="full_content">
          <Grid container columns={{ xs: 12, sm: 12, md: 12 }} className={isDrawerOpen ? 'full_container_drawer' : 'full_container'}>
            {!isMobile ? DesktopIntroBody : MobileIntroBody}
          </Grid>
        </Box>
      </div>
      <NewUserTerms open={openGdpr} onClose={oncloseGdpr} />
    </>
  );
};

export default observer(CourseIntroduction);
