import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, Box, Button, FormControl, InputAdornment, InputLabel, Popover, Stack, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { endOfYear, format, set, startOfYear } from 'date-fns';
import nl from 'date-fns/locale/nl';
import { useStore } from 'hooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFullYear, isSchoolYear } from '../utils';

const OPTIONS = {
  year: 'year',
  schoolYear: 'schoolYear',
  custom: 'custom'
};

function getRangeInput({ from, to, translation, isEwiseLabel }) {
  if (!from || !to) return '';
  const fromDate = new Date(from);
  const toDate = new Date(to);
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(23, 59, 59, 999);

  if (!isEwiseLabel && isFullYear(fromDate, toDate)) {
    return `${translation.year}-${format(fromDate, 'yyyy')}`;
  }
  if (isEwiseLabel && isSchoolYear(fromDate, toDate)) {
    const shoolYear = toDate.getFullYear() === fromDate.getFullYear() ? toDate.getFullYear() + 1 : toDate.getFullYear();
    return `${translation.schoolYear}-${shoolYear}`;
  }

  return `${format(fromDate, 'dd-MM-yyyy')} - ${format(toDate, 'dd-MM-yyyy')}`;
}

const DateRange = ({ handleRangeDate, from, to }) => {
  const store = useStore();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const isEwiseLabel = store?.label === 'ewise';
  const [range, setRange] = useState(() => {
    if (!from || !to) return null;
    const fromDate = new Date(from);
    const toDate = new Date(to);
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    if (!isEwiseLabel && isFullYear(fromDate, toDate)) {
      return OPTIONS.year;
    }
    if (isEwiseLabel && isSchoolYear(fromDate, toDate)) {
      return OPTIONS.schoolYear;
    }
    return OPTIONS.custom;
  });

  const maxDate = new Date();
  const minDate = new Date(2022, 0, 1);

  const [fromDate, setFromDate] = useState(() => {
    if (!from || !to) return minDate;
    const _fromDate = new Date(from);
    const currentDate = new Date();
    return _fromDate.getTime() < currentDate.getTime() ? _fromDate : minDate;
  });
  const [toDate, setToDate] = useState(() => {
    if (!from || !to) return maxDate;
    const _toDate = new Date(to);
    return _toDate.getTime() < maxDate.getTime() ? _toDate : maxDate;
  });

  const [schoolYear, setSchoolYear] = useState(from ? set(new Date(from), { year: new Date(from).getFullYear() + 1 }) : new Date());

  const rangeInputValue = getRangeInput({
    from,
    to,
    translation: {
      year: t('lms.toolBar.dateRange.year'),
      schoolYear: t('lms.toolBar.dateRange.schoolYear')
    },
    isEwiseLabel
  });

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const handleClickApply = () => {
    if (typeof handleRangeDate !== 'function') {
      return console.error('date range handleRangeDate should be a function');
    }

    if (range === OPTIONS.year) {
      const startDate = startOfYear(toDate);
      const endDate = toDate.getFullYear() === maxDate.getFullYear() ? maxDate : endOfYear(toDate);
      handleRangeDate({ from: startDate, to: endDate });
    } else if (range === OPTIONS.schoolYear) {
      handleRangeDate({ from: fromDate, to: toDate });
    } else if (range === OPTIONS.custom) {
      handleRangeDate({ from: fromDate, to: toDate });
    } else {
      handleRangeDate({ from: '', to: '' });
    }
    return closePopover();
  };

  const open = Boolean(anchorEl);

  const handClickResetDefault = () => {
    const currentDate = new Date();
    let startDate;
    const endDate = currentDate;
    if (isEwiseLabel) {
      startDate =
        currentDate.getMonth() < 7
          ? set(currentDate, { year: currentDate.getFullYear() - 1, month: 7, date: 1 })
          : set(currentDate, { year: currentDate.getFullYear(), month: 7, date: 1 });

      setRange(OPTIONS.schoolYear);
    } else {
      startDate = set(currentDate, { month: 0, date: 1, hours: 0, minutes: 0, seconds: 0 });
      setRange(OPTIONS.year);
    }

    setFromDate(startDate);
    setToDate(endDate);
    handleRangeDate({ from: startDate, to: endDate });
    closePopover();
  };
  return (
    <>
      <TextField
        label={t('lms.toolBar.dateRange.label')}
        placeholder={t('lms.toolBar.dateRange.placeholder')}
        onClick={openPopover}
        value={rangeInputValue}
        sx={{ input: { cursor: 'default' } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
      />
      <Popover
        id="filter-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Stack maxWidth="100%" p={2} gap={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">{t('lms.toolBar.dateRange.title')}</Typography>
            <Button variant="outlined" color="secondary" onClick={handClickResetDefault}>
              {t('lms.toolBar.dateRange.reset')}
            </Button>
          </Stack>

          <Autocomplete
            variant="customAutocomplete"
            options={
              isEwiseLabel
                ? Object.keys(OPTIONS).filter((opt) => opt !== OPTIONS.year)
                : Object.keys(OPTIONS).filter((opt) => opt !== OPTIONS.schoolYear)
            }
            getOptionLabel={(option) => t(`lms.toolBar.dateRange.${option}`)}
            value={range}
            onChange={(e, value) => {
              setRange(value);
              if (value === OPTIONS.custom) {
                setFromDate(minDate);
                setToDate(maxDate);
              }
            }}
            renderInput={(params) => (
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink bold="true">
                  {t('lms.toolBar.dateRange.label')}
                </InputLabel>
                <Box mt={3}>
                  <TextField {...params} placeholder={t('lms.toolBar.dateRange.placeholder')} />
                </Box>
              </FormControl>
            )}
          />
          {[OPTIONS.schoolYear, OPTIONS.year].includes(range) && (
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink bold="true">
                {t('lms.toolBar.dateRange.year')}
              </InputLabel>
              <Box mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                  {range === OPTIONS.schoolYear ? (
                    <DatePicker
                      views={['year']}
                      minDate={set(minDate, { year: minDate.getFullYear() + 1, month: 7, date: 1 })}
                      maxDate={set(maxDate, { year: maxDate.getFullYear() + 1, month: 7, date: 1 })}
                      value={schoolYear}
                      onChange={(value) => {
                        setSchoolYear(value);
                        setFromDate(set(value, { year: value.getFullYear() - 1, month: 7, date: 1 }));
                        setToDate(value, { year: value.getFullYear(), month: 7, date: 1 });
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      PopperProps={{
                        sx: {
                          '& .MuiYearPicker-root': {
                            flexDirection: 'column',
                            '& button': {
                              fontSize: '18px',
                              lineHeight: '18px',
                              margin: '5px 0'
                            }
                          }
                        }
                      }}
                    />
                  ) : (
                    <DatePicker
                      views={['year']}
                      minDate={minDate}
                      maxDate={maxDate}
                      value={toDate}
                      onChange={(value) => {
                        setFromDate(value);
                        setToDate(value);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      PopperProps={{
                        sx: {
                          '& .MuiYearPicker-root': {
                            flexDirection: 'column',
                            '& button': {
                              fontSize: '18px',
                              lineHeight: '18px',
                              margin: '5px 0'
                            }
                          }
                        }
                      }}
                    />
                  )}
                </LocalizationProvider>
              </Box>
            </FormControl>
          )}

          {range === OPTIONS.custom && (
            <>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink bold="true">
                  {t('lms.toolBar.dateRange.from')}
                </InputLabel>
                <Box mt={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                    <DatePicker
                      minDate={minDate}
                      maxDate={toDate}
                      value={fromDate}
                      onChange={(value) => {
                        if (value) {
                          value.setHours(0, 0, 0);
                        }
                        setFromDate(value);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Box>
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink bold="true">
                  {t('lms.toolBar.dateRange.to')}
                </InputLabel>
                <Box mt={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                    <DatePicker
                      minDate={fromDate}
                      maxDate={maxDate}
                      value={toDate}
                      onChange={(value) => {
                        if (value) {
                          value.setHours(23, 59, 59);
                        }
                        setToDate(value);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Box>
              </FormControl>
            </>
          )}

          <Stack mt="auto" direction="row" gap={2}>
            <Button variant="outlined" color="secondary" fullWidth onClick={closePopover}>
              {t('lms.toolBar.dateRange.cancel')}
            </Button>
            <Button variant="contained" color="primary" fullWidth onClick={handleClickApply}>
              {t('lms.toolBar.dateRange.apply')}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default DateRange;

DateRange.propTypes = {
  handleRangeDate: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string
};
