import { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { getThemeName, rawHTML } from 'helpers/Helpers';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { keyframes } from '@emotion/react';
/* Custom styling */
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, useMediaQuery, Box } from '@mui/material';
import AlertIcon from '@mui/icons-material/HelpOutline';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& h2': { color: theme?.palette?.primary?.main },
    '& a': { color: theme?.palette?.primary?.main }
  }
}));

/**
 * This component is responsible for displaying an introductory message from a package to the user, providing the option to access more information in a popup dialog, and managing the presentation of additional content.
 */
const CourseOverviewIntro = () => {
  const store = useContext(Store); // Use useContext to access the store
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const selectedPackage = store.packages.filter((pack) => pack.nid === store.selectedPackageId);
  const selectedPackageBody = selectedPackage[0]?.body || '';

  const selectedPackageTitle = selectedPackage[0]?.tab_label?.toLowerCase() || '';
  const isArchive = !!(selectedPackageTitle !== '' && selectedPackageTitle?.includes('archief'));
  const themeName = getThemeName();
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  return isArchive ? (
    <Box className={`${classes.root} ${themeName} intro_full`}>{rawHTML(selectedPackageBody)}</Box>
  ) : (
    selectedPackageBody !== '' && (
      <>
        <Alert
          icon={<AlertIcon />}
          severity="info"
          variant="filled"
          color="warning"
          sx={{ justifyContent: 'center', alignItems: 'center', animation: `${pulse} 1s 1` }}
        >
          <AlertTitle sx={{ margin: '0px' }}>
            {t('courseoverview.intro.title', 'There is important information for you to read')}{' '}
            <Button variant="contained" color="primary" onClick={handleOpenPopup} sx={{ marginLeft: '16px' }}>
              {t('courseoverview.intro.button', 'Read')}
            </Button>
          </AlertTitle>
        </Alert>
        <Dialog
          aria-labelledby="responsive-dialog-title"
          maxWidth="md"
          color="primary"
          className={`${classes.root} ${themeName} intro_dialog`}
          open={openPopup}
          onClose={handleClosePopup}
          fullWidth
          fullScreen={fullScreen}
        >
          <DialogContent>
            {/* Add more information content here */}
            {rawHTML(selectedPackageBody)}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePopup} color="primary">
              {t('courseoverview.intro.close', 'Close')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default observer(CourseOverviewIntro);
