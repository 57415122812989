/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { useStore } from 'hooks';
import { ThemeBrandTitle } from 'helpers/Helpers'; // Assuming convertUrlDecodedString is a helper function
import CourseList from './CourseList';
import NoResultsMessage from './NoResults/NoResultsMessage';
import AccreditationNotice from './elements/AccreditationNotice';

/**
 * CourseTable component
 *
 * Displays a table view of courses fetched from MobX store.
 * Automatically adjusts course image sizes on mount.
 */
const CourseTable = () => {
  const store = useStore();
  const { t } = useTranslation();
  const brandTitle = ThemeBrandTitle();
  const isSchoolleiders = store.baseProduct.unique_name.includes('schoolleiders');

  // Render no results message if no courses or courses are undefined
  if (!store.courses?.courses || store.courses.courses.length === 0) {
    return (
      <Box>
        <NoResultsMessage />
      </Box>
    );
  }

  // Split for accredited professions
  const { accr, nonaccr } = store.courses.coursesByAccreditation();

  return (
    <Box data-testid="container-view-list-condensed" sx={{ flexGrow: 1 }}>
      {store.searchIsRandom === true && (
        <Box sx={{ marginBottom: '16px' }}>
          <NoResultsMessage />
        </Box>
      )}
      <Grid className="grid__main__container" container spacing={2}>
        {accr.length > 0 && (brandTitle !== 'E-WISE' || isSchoolleiders) && (
          <>
            <Grid className="courseview_category" item xs={12} style={{ flexBasis: '100%' }} data-testid="category-section">
              <Typography
                className="grid_category_title"
                variant="h2"
                component="h2"
                color="primary"
                data-testid="category-title"
                sx={{ fontSize: 22 }}
              >
                {t('searchoverview.accreditation')}
              </Typography>
            </Grid>
            <CourseList courses={accr} isFiltering />
          </>
        )}
        {nonaccr.length > 0 && (brandTitle !== 'E-WISE' || isSchoolleiders) && (
          <>
            {accr.length > 0 && (
              <Grid className="courseview_category" item xs={12} style={{ flexBasis: '100%' }} data-testid="category-section">
                <Typography
                  className="grid_category_title"
                  variant="h2"
                  component="h2"
                  color="primary"
                  data-testid="category-title"
                  sx={{ fontSize: 22 }}
                >
                  {t('searchoverview.otherCourses')}
                </Typography>
              </Grid>
            )}
            <Grid className="searchoverview_accr_notice" item xs={12} style={{ flexBasis: '100%' }}>
              <AccreditationNotice />
            </Grid>
            <CourseList courses={nonaccr} isFiltering />
          </>
        )}
        {brandTitle === 'E-WISE' && !isSchoolleiders && <CourseList courses={store.courses?.courses} isFiltering />}
      </Grid>
    </Box>
  );
};

export default observer(CourseTable);
