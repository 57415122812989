/* Course categories */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';

/* MUI */
import { Chip, Box, Tooltip } from '@mui/material';

// renders a list of categories for a course card
const CourseCategories = (props) => {
  const store = useContext(Store);

  const { categories, noStatus } = props;

  // IF NO CATEGORIES, RETURN NULL
  if (!categories || !store.courses.isFiltering) return null;

  // Categories <= 2: show all
  const showCategories = categories.length > 2 ? categories.slice(0, 2) : categories;

  // Categories > 2: map and join titles to show in tooltip
  let restCategories = categories.length > 2 ? categories.slice(2) : [];
  restCategories = restCategories.length > 0 ? restCategories.map((item) => item).join(', ') : '';

  return (
    <Box className={noStatus ? 'card__categories' : 'card__categories noStatus'}>
      {showCategories.map((category, index) => (
        <Tooltip title={category} arrow key={index}>
          <Chip className="chip_category" key={index} label={category} variant="outlined" />
        </Tooltip>
      ))}
      {categories.length - 2 > 0 && (
        <Tooltip title={restCategories} arrow key={100}>
          <Chip className="chip_category" key={1} label={`+${categories.length - 2}`} variant="outlined" />
        </Tooltip>
      )}
    </Box>
  );
};

CourseCategories.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  noStatus: PropTypes.any
};

export default observer(CourseCategories);
