/* Core */
import PropTypes from 'prop-types';

/* MUI */
import { Box, Avatar, Tooltip } from '@mui/material';

// renders a list of badges for a course card
const CourseSymbols = (props) => {
  const { badges, small = false } = props;
  // IF NO BADGES, RETURN NULL
  if (!badges || badges.length === 0) return null;

  const sxNPO = {
    '[class*="#"], [class*="NPO"], [class*="symbol-VE"], [class*="symbol-A1"], [class*="symbol-A2"]': {
      width: 'fit-content!important',
      borderRadius: '6px',
      padding: '0px 6px',
      background: '#ffffff !important',
      color: '#01689b !important',
      border: '1px solid #01689b'
    }
  };

  const npoBadges = badges.filter((badge) => badge?.name.toLowerCase().includes('npo'));
  const goBadges = badges.filter((badge) => badge?.name.toLowerCase() === 'groepsopdracht');
  const veBadges = badges.filter((badge) => badge?.name.toLowerCase() === 've');
  const aBadges = badges.filter((badge) => badge?.name.toLowerCase() === 'a1' || badge?.name.toLowerCase() === 'a2');
  const restBadges = badges.filter(
    (badge) =>
      !badge?.name.toLowerCase().includes('npo') &&
      badge?.name.toLowerCase() !== 'groepsopdracht' &&
      badge?.name.toLowerCase() !== 've' &&
      badge?.name.toLowerCase() !== 'a1' &&
      badge?.name.toLowerCase() !== 'a2'
  );
  restBadges.sort((a, b) => a.name.localeCompare(b.name));

  const sortedBadges = [...goBadges, ...npoBadges, ...veBadges, ...aBadges, ...restBadges];

  const sxBadgeSize = small ? { width: '24px', height: '24px', fontSize: '13px' } : {};

  const getFirstLetter = (badge) => {
    if (badge?.name) return badge.name.charAt(0);
    return 'unknown';
  };
  const getBadgeTitle = (badge) => {
    if (badge.symbol?.includes('#')) return [...badge.symbol][1];
    if (badge?.name?.toLowerCase().includes('npo')) return badge.name;
    return badge.symbol ? badge.symbol : [...badge.name][0];
  };

  // RETURN BADGES
  return (
    <Box
      className="course_overview_card_accreditations"
      component="div"
      sx={{ display: 'flex', gap: '2px', flexWrap: 'wrap', justifyContent: 'flex-end', ...sxNPO }}
    >
      {sortedBadges.map((badge, index) => (
        <Tooltip title={badge.name} arrow key={`chip_${index}`}>
          <Avatar sx={sxBadgeSize} className={`symbol-${badge?.symbol ? badge.symbol : getFirstLetter(badge)} ${badge.name}`}>
            {getBadgeTitle(badge)}
          </Avatar>
        </Tooltip>
      ))}
    </Box>
  );
};

CourseSymbols.propTypes = {
  badges: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  small: PropTypes.bool
};

export default CourseSymbols;
