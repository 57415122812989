/* Core */
import { useEffect } from 'react';
import { observer } from 'mobx-react';

/* Custom components */
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useBreakpoint, useStore } from 'hooks';
import 'intro.js/introjs.css';
import { useTranslation } from 'react-i18next';
import CourseGrid from './components/CourseOverview/Grid/CourseGrid';
import CourseGridListMin from './components/CourseOverview/Grid/CourseGridListMin';
import CourseTabs from './components/CourseOverview/CourseTabs';
import CourseFavorites from './components/CourseOverview/CourseFavorites';
import CourseStarted from './components/CourseOverview/CourseStarted';

import CourseView from './components/CourseOverview/CourseView';
import { FilterContainer } from './components/CourseOverview/RightSideBar/FilterContainer';
import GridCardSwipe from './components/CourseOverview/Grid/GridCardSwipe';
import Banner from './components/CourseOverview/Banner';
import CourseFinished from './components/CourseOverview/CourseFinished';
import CourseOverviewIntro from './components/CourseOverview/Intro/CourseOverviewIntro';
import CourseOverviewIntroAccr from './components/CourseOverview/Intro/CourseOverviewIntroAccr';
import { useOnboarding } from './useOnboarding';
import TopLinks from './components/CourseOverview/TopLinks';
import CourseTable from './components/CourseOverview/Grid/CourseTable/CourseTable';

/**
 *  CourseOverview
 *
 *  The CourseOverview component is the main component for displaying courses.
 *  It provides a user interface for selecting tabs, viewing courses, and applying filters.
 *  The component's behavior is influenced by the state managed by MobX, and it's designed to be responsive to different screen sizes.
 */
const CourseOverview = () => {
  const { t } = useTranslation('onboarding');

  const store = useStore();

  const isSpecialAccessView = store.routerState.routeName.includes('specialAccess');

  const mobileBreakpoint = useBreakpoint();
  const showFilter = !isSpecialAccessView && store.isfilterVisible && mobileBreakpoint === 'xl' && store.courseHeaderTabs === 'All';
  const boxClassName = `course_overview_box ${isSpecialAccessView ? 'special_access' : ''}`;

  useEffect(() => {
    if (mobileBreakpoint === 'md') {
      store.setFilter(false);
    }
    if (store.selectedPackageId === store.prevTab) {
      window.scroll(0, store.scrollPosition);
    }
  }, [mobileBreakpoint, store]);
  useOnboarding(store, t);

  const courseHeaderTabs =
    store.courseHeaderTabs === 'Started' || store.courseHeaderTabs === 'Favourites' || store.courseHeaderTabs === 'Finished';

  const getClassName = {
    1: 'course_overview_list_min',
    2: 'course_overview_grid',
    3: 'course_overview_list'
  };

  const courseViewStyling = getClassName[store.courseoverview];

  if (
    store.login?.isVtbTeacher === 1 &&
    isSpecialAccessView &&
    (store.courses?.courses?.length === 0 || store.courses?.courses === undefined)
  ) {
    return (
      <Box
        className="vtb_teacher_dashboard"
        sx={{ margin: '16px', marginTop: '80px', backgroundColor: '#fff', padding: '16px', textAlign: 'center' }}
      >
        <Typography color="primaryBlue.main" variant="h1" fontSize="18px">
          {t('isVtbTeacher', 'You can choose from the various options on the left side.')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="course_overview">
      {!isSpecialAccessView && <TopLinks current="courseOverview" />}
      <Box className={boxClassName}>
        <Box component="main" className="course_overview_filterbar2">
          <Box display="flex">
            <Box className={showFilter ? 'course_overview_wrap' : 'course_overview_wrap_filter'}>
              <Box>
                <Banner />
                <Grid
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                  container
                  spacing={2}
                  className="course_overview_tabs onboarding-filters-bar"
                  rowGap={2}
                >
                  <Grid item xs={12} sm={8} md={9} lg={8} xl={8}>
                    <CourseTabs />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={4} xl={4}>
                    <CourseView />
                  </Grid>
                </Grid>
              </Box>
              {store.courseHeaderTabs === 'Favourites' && (
                <div className={courseViewStyling}>
                  <CourseFavorites />
                </div>
              )}
              {store.courseHeaderTabs === 'Started' && (
                <div className={courseViewStyling}>
                  <CourseStarted />
                </div>
              )}
              {store.courseHeaderTabs === 'Finished' && (
                <div className={courseViewStyling}>
                  <CourseFinished />
                </div>
              )}
              {!courseHeaderTabs && <CourseViewAll />}
            </Box>
            {showFilter && <FilterContainer />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(CourseOverview);

const Spinner = () => (
  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '85vh' }}>
    <CircularProgress color="primary" style={{ width: '65px', height: '65px' }} />
  </Box>
);

const CourseViewAll = observer(() => {
  const store = useStore();
  const currentBreakpoint = useBreakpoint();

  const isSwipable = ['xs', 'sm'].includes(currentBreakpoint) && !store.courses?.isFiltering;

  if (store.loadingCourses === false) {
    return <Spinner />;
  }

  if (store.courseoverview === 1) {
    return (
      <Box className="course_overview_list_min">
        <CourseOverviewIntro />
        <CourseOverviewIntroAccr />
        <CourseGridListMin />
      </Box>
    );
  }
  if (store.courseoverview === 2) {
    return (
      <div className="course_overview_grid">
        {isSwipable ? (
          <GridCardSwipe />
        ) : (
          <>
            <CourseOverviewIntro />
            <CourseOverviewIntroAccr />
            <CourseGrid />
          </>
        )}
      </div>
    );
  }
  if (store.courseoverview === 3) {
    return (
      <Box className="course_overview_list">
        <CourseOverviewIntro />
        <CourseOverviewIntroAccr />
        <CourseTable />
      </Box>
    );
  }
  return null;
});
