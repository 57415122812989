import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { useStore } from 'hooks';

import { Fragment } from 'react';
import AccountButton from './components/AccountButton';
import AssistantButton from './components/AssistantButton';
import CourseOverviewButton from './components/CourseOverviewButton';
import CourseSearch from './components/CourseSearch';
import LmsButton from './components/LmsButton';
import MainMenu from './components/MainMenu';
import NotificationsItem from './components/NotificationsItem';
import QuickLinksMenu from './components/QuickLinksMenu';
import SelectProductButton from './components/SelectProductButton';
import SpecialAccessButton from './components/SpecialAccessButton';
import TotalSubPoints from './components/TotalSubPoints';
import { useHeaderContext } from './context';

const CourseHeader = () => {
  const store = useStore();

  const { showSidebar, hasSubscriptions } = useHeaderContext();
  const { isMobile, isSmallScreen } = useHeaderContext();

  const showNotifications = false;
  const showPoints = store?.subscriptions?.subscriptions?.length > 0;
  const showLmsButton = store.showMenuItemLms && (!hasSubscriptions || !showSidebar) && !store.isEwiseUser;
  const showAssistantButton = store.showMenuAssistant && (!hasSubscriptions || !showSidebar) && !store.isEwiseUser;
  const showSpecialAccess = !showSidebar && store.login?.hasSpecialAccess;
  const showCourseOverview = !showSidebar && hasSubscriptions;
  const showSelectProduct = store.product && (store.products.length > 1 || store.isEwiseUser);

  const QuickLinksComponent = isMobile ? QuickLinksMenu : Fragment;

  return (
    <Box data-testid="headline" className="course_overview_headline_icons">
      <Box className="course_overview_icons">
        <CourseSearch />

        <Box className="course_overview_notifications">
          {showNotifications && <NotificationsItem />}

          {showPoints && <TotalSubPoints />}
          {!isSmallScreen && showSelectProduct && <SelectProductButton />}

          <QuickLinksComponent>
            {isSmallScreen && showSelectProduct && <SelectProductButton isInMenu />}
            {showCourseOverview && <CourseOverviewButton />}
            {showSpecialAccess && <SpecialAccessButton />}
            {showAssistantButton && <AssistantButton />}
            {showLmsButton && <LmsButton />}
          </QuickLinksComponent>

          <AccountButton />
          <MainMenu />
        </Box>
      </Box>
    </Box>
  );
};

export default observer(CourseHeader);
