import { toJS } from 'mobx';
import { types as t, getParent, flow } from 'mobx-state-tree';

const chartModel = t.model('chart', {
  status: t.string, // 'idle' | 'isLoading' | 'hasError' | 'isSuccess' | 'hasNoOrganisations'
  data: t.array(
    t.model({
      month: t.string,
      monthdisplay: t.string,
      students: t.number,
      login: t.number,
      completed: t.number,
      points: t.number
    })
  )
});
const profesionsModel = t.model('professions array', {
  status: t.string, // 'idle' | 'isLoading' | 'hasError' | 'isSuccess'
  data: t.array(
    t.model('profession', {
      profession: t.string,
      professionId: t.number,
      students: t.number,
      login: t.number,
      open_courses: t.number,
      completed: t.number,
      points: t.number,
      students_with_finished_course: t.number,
      students_with_open_course: t.number
    })
  )
});
const productsModel = t.model('Products', {
  status: t.string, // 'idle' | 'isLoading' | 'hasError' | 'isSuccess'
  data: t.array(
    t.model({
      product: t.string,
      productId: t.number,
      profession: t.string,
      professionId: t.number,
      students: t.number,
      login: t.number,
      completed: t.number,
      open_courses: t.number,
      students_with_open_course: t.number,
      points: t.number,
      students_with_finished_course: t.number
    })
  )
});

const dashboardModel = t
  .model('LmsDashboard', {
    chart: t.maybeNull(chartModel),
    professions: t.maybeNull(profesionsModel),
    products: t.maybeNull(productsModel)
  })
  .views((self) => ({
    get chartData() {
      return toJS(self.chart.data);
    },
    get professionsData() {
      return toJS(self.professions.data);
    },
    get productsData() {
      return toJS(self.products.data);
    }
  }))
  .actions((self) => ({
    fetchLmsProfessions: flow(function* fetchLmsProfessions(query) {
      self.professions.status = 'isLoading';
      if (!getParent(self, 1).lmsAccess?.organisations?.length) {
        self.professions.status = 'hasNoOrganisations';
        self.professions.data = [];
        return;
      }
      try {
        const response = yield getParent(self, 1).lmsApi.fetchLMSProfessions(query);
        const { data } = response;
        self.professions.data = data;
        self.professions.status = 'isSuccess';
      } catch (error) {
        self.professions.status = 'hasError';
        self.professions.data = [];
      }
    }),
    fetchLmsProducts: flow(function* fetchLmsProducts(query) {
      self.products.status = 'isLoading';
      if (!getParent(self, 1).lmsAccess?.organisations?.length) {
        self.products.status = 'hasNoOrganisations';
        self.products.data = [];
        return;
      }
      try {
        const response = yield getParent(self, 1).lmsApi.fetchLMSProducts(query);
        const { data } = response;
        self.products.data = data;
        self.products.status = 'isSuccess';
      } catch (error) {
        self.products.status = 'hasError';
        self.products.data = [];
      }
    }),
    fetchLmsChart: flow(function* fetchLmsChart(query) {
      self.chart.status = 'isLoading';
      if (!getParent(self, 1).lmsAccess?.organisations?.length) {
        self.chart.status = 'hasNoOrganisations';
        self.chart.data = [];
        return;
      }
      try {
        const response = yield getParent(self, 1).lmsApi.fetchLMSChart(query);
        const { data } = response;
        self.chart.data = data;
        self.chart.status = 'isSuccess';
      } catch (error) {
        self.chart.status = 'hasError';
        self.chart.data = [];
      }
    })
  }));

export const LmsDashboard = t.optional(dashboardModel, {
  chart: {
    status: 'idle',
    data: []
  },
  products: {
    status: 'idle',
    data: []
  },
  professions: {
    status: 'idle',
    data: []
  }
});
