/* MUI */
import { Tooltip, IconButton, Box } from '@mui/material';
import PodcastsIcon from '@mui/icons-material/Podcasts';

// DEFAULT COLORS FOR PODCASTS (orange)
const PODCAST_BACKGROUND_COLOR = '#ffa601';

// renders the podcast icon
const PodcastIcon = () => (
  <Box className="podcast_wrapper">
    <Tooltip title="Podcast" arrow>
      <IconButton
        className="podcast_icon"
        sx={{ backgroundColor: PODCAST_BACKGROUND_COLOR, '&:hover': { backgroundColor: PODCAST_BACKGROUND_COLOR } }}
      >
        <PodcastsIcon style={{ fill: 'white' }} />
      </IconButton>
    </Tooltip>
  </Box>
);

export default PodcastIcon;
