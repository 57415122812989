import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import * as PropTypes from 'prop-types';
import { StatPanel } from './StatPanel';

export const StatsPanel = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={{ sm: 1, md: 2 }}>
      <Grid item xs={12} sm={12} md={4}>
        <StatPanel
          icon={<PersonIcon sx={{ fontSize: '2em' }} />}
          label={t('assistant.dashboard.active')}
          statNumber={data.assistant_manager.attributes?.subscriptions_used}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <StatPanel
          icon={<CardMembershipIcon sx={{ fontSize: '2em' }} />}
          label={t('assistant.dashboard.licenses')}
          statNumber={data.assistant_manager.attributes?.maximum_subscriptions - data.assistant_manager.attributes?.subscriptions_used}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <StatPanel
          icon={<CardMembershipIcon sx={{ fontSize: '2em' }} />}
          label={t('assistant.dashboard.total')}
          statNumber={data.assistant_manager.attributes?.maximum_subscriptions}
        />
      </Grid>
    </Grid>
  );
};

StatsPanel.propTypes = { data: PropTypes.any };
