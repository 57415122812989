/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { courseOverviewImgSize } from '../../../../../../helpers/Helpers';
import { useStore } from '../../../../../../hooks';
import CourseList from './CourseList';

const CourseTable = () => {
  const store = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    courseOverviewImgSize();
  }); // Run this effect when the loads

  if (store.courses?.courses?.length === 0 || store.courses?.courses === undefined) {
    return (
      <Box className="noFavorite_section">
        <Typography color="primaryBlue.main" variant="h1" fontSize="27px">
          {t('courseoverview.noCourses', 'No courses available')}
        </Typography>
      </Box>
    );
  }

  const isFiltering = store.courses?.isFiltering;
  const currentCourses = store.courses?.courseList;
  const categories = store.courses?.categories;

  const handleClearFilters = () => {
    store.courses?.resetFilter();
  };

  if (store.courses?.isSpecialAccessView) {
    return (
      <Box sx={{ flexGrow: 1 }} data-testid="container-view-grid">
        <Grid className="grid__main__container" container direction="row" spacing={2}>
          <CourseList courses={currentCourses} isFiltering />
        </Grid>
      </Box>
    );
  }

  return (
    <Box data-testid="container-view-list-condensed" sx={{ flexGrow: 1 }}>
      <Grid className="grid__main__container" container spacing={2}>
        {isFiltering ? (
          <>
            <Grid item xs={12}>
              <Box className="filteredResults">
                <Typography variant="h2" component="h2" fontSize="27px" color="primary">
                  {t('courseoverview.filteredResults', 'Filtered results')}: {currentCourses.length}
                </Typography>
                {store.courses.isFiltered && !store.isfilterVisible && (
                  <Button onClick={handleClearFilters}>{t('course.filters.clear')}</Button>
                )}
              </Box>
            </Grid>
            <CourseList courses={currentCourses} isFiltering />
          </>
        ) : (
          categories
            .sort((a, b) => a.weight - b.weight)
            .map((category) => {
              const courses = store.courses.coursesByCategoryId(category.tid);
              const categoryDescription = category.description !== null;
              return (
                <React.Fragment key={category.tid}>
                  <Grid className="courseview_category" item xs={12}>
                    <Typography variant="h5" component="h2" color="primary" sx={{ fontSize: 22 }}>
                      {category.name}
                    </Typography>
                    {categoryDescription && (
                      <Box className="courseview_category_introduction">
                        <div dangerouslySetInnerHTML={{ __html: category.description }} />
                      </Box>
                    )}
                  </Grid>
                  <CourseList courses={courses} />
                </React.Fragment>
              );
            })
        )}
      </Grid>
    </Box>
  );
};

export default observer(CourseTable);
