/* eslint-disable camelcase */
/* Core */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

/* MUI */
import Box from '@mui/material/Box';
import { Typography, Grid } from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';

/* Custom components */
import CalculateProgress from 'pages/course/components/Common/CalculateProgress';
import { LinearProgressWithLabel } from 'components';
import { isCourseFailed, getLabelNameFromDomain, embedVideo } from 'helpers/Helpers';
import Video from 'components/widgets/Video';
import ReactPlayer from 'react-player';
import CourseSymbols from '../../CourseOverview/Card/elements/CourseSymbols';
import CourseReactions from '../../CourseOverview/Card/elements/CourseReactions';
import CourseIntroductionAuthors from '../CourseIntroductionAuthors';
import MobileTopbar from './MobileTopbar';
import CourseStatus from '../../CourseOverview/Card/elements/CourseStatus';

const MobileCourseIntroductionHeader = (props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line camelcase
  const {
    title,
    subTitle,
    badges,
    id,
    uuid,
    lesson,
    chip,
    authors,
    video,
    isAccredited,
    accreditation,
    lessons,
    duration,
    endDate,
    courseStatus,
    extraPoints,
    extraPointsDescr,
    isCourseExpiring
  } = props;
  const store = useContext(Store);
  const { isLessonFailed } = store.lesson_results;
  const { isCourseStarted } = store.courseResults;

  const isStarted = isCourseStarted(id);
  const isFailed = isCourseFailed(store.lesson_results, store.courseResults, id);
  const currentTheme = getLabelNameFromDomain();
  const showAuthors = currentTheme === 'po';

  const previewVideo = video && embedVideo(video);
  const isUrlCorrect = ReactPlayer.canPlay(previewVideo);

  const VideoError = () => (
    <Box sx={{ textAlign: 'center' }}>
      <CloudOffIcon sx={{ fontSize: 60, color: 'silver' }} />
      <Box>{t('course.tabs.videoError', 'Something went wrong loading the video')}</Box>
    </Box>
  );

  return (
    <>
      <Box className="header_mobile" id="header">
        {previewVideo && (
          <Box className="course_preview" id="Preview">
            {!isUrlCorrect && <VideoError />}
            {isUrlCorrect && (
              <Box className="video-wrapper">
                <Video className="video-player" url={previewVideo} width="100%" height="100%" />
              </Box>
            )}
          </Box>
        )}
        <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid item xs={10} sm={10}>
            <Box className="chips_box">
              <CourseStatus status={chip} isCourseExpiring={isCourseExpiring} />
            </Box>

            <Box className="course_information_mobile">
              <Box className="content">
                <Typography variant="h1" component="h1" color="primaryBlue.main" fontSize="24px">
                  {title}
                </Typography>
                {subTitle !== '' && (
                  <Typography fontStyle="italic" fontSize="18px" color="favoriteRed.main">
                    {subTitle}
                  </Typography>
                )}
                {showAuthors && authors && authors.length > 0 && <CourseIntroductionAuthors authors={authors} />}
                <Box className="course_accreditations" component="div">
                  <CourseSymbols badges={badges} />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2}>
            <Box className="favorites_box">
              <CourseReactions id={uuid} lessons={lesson} nid={id} courseIntroduction />
            </Box>
          </Grid>
          {lesson.length === 1 && isStarted && store.courseResults.courseResults.length && !isLessonFailed(id) && !isFailed && (
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                padding: '10px',
                background: '#f2f6f9'
              }}
            >
              <Box className="status">
                <Grid container gap="4px" flexDirection="column" justifyContent="center">
                  <Grid item xs={12}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      {store.courseResults.courseResults.length ? (
                        <CalculateProgress id={id} uuid={uuid} lessons={lesson} isInHeader />
                      ) : (
                        <LinearProgressWithLabel value={0} color="secondaryOrange" />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
        <MobileTopbar
          id={id}
          isAccredited={isAccredited}
          accreditation={accreditation}
          duration={duration}
          extraPoints={extraPoints}
          extraPointsDescr={extraPointsDescr}
          title={title}
          lessons={lessons}
          uuid={uuid}
          endDate={endDate}
          courseStatus={courseStatus}
        />
      </Box>
    </>
  );
};

MobileCourseIntroductionHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  badges: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  id: PropTypes.number,
  uuid: PropTypes.string,
  lesson: PropTypes.any,
  chip: PropTypes.string,
  authors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  video: PropTypes.any,
  isAccredited: PropTypes.bool,
  accreditation: PropTypes.string,
  duration: PropTypes.number,
  extraPoints: PropTypes.number,
  extraPointsDescr: PropTypes.string,
  lessons: PropTypes.any,
  endDate: PropTypes.string,
  courseStatus: PropTypes.string,
  isCourseExpiring: PropTypes.bool
};

export default observer(MobileCourseIntroductionHeader);
