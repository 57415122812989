import { attachRelation } from './helpers/attach-relation';
import { detachRelation } from './helpers/detach-relation';

export const handleApiError = (error) => {
  if (error?.response?.data?.errors) {
    return error.response.data;
  }

  return {
    status: error?.response?.status ?? 500,
    statusText: error?.response?.statusText ?? 'An unknown server error occurred',
    errors: [{ message: 'An unknown server error occurred' }]
  };
};

export const createJsonApi = ({ endpoint, params: globalParams = {} }) => ({
  async getAll(params) {
    try {
      const result = await window.authedClient.get(`jsonapi/${endpoint}`, {
        params: {
          ...globalParams,
          ...params
        }
      });
      return result.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  async getSingle(id, params) {
    try {
      const result = await window.authedClient.get(`jsonapi/${endpoint}/${id}`, {
        params: {
          ...globalParams,
          ...params
        }
      });

      return result.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  async create(props) {
    const data = {
      type: endpoint,
      attributes: props.attributes ?? props,
      relationships: props.relationships
    };

    try {
      return await window.authedClient.post(`jsonapi/${endpoint}`, { data });
    } catch (error) {
      return handleApiError(error);
    }
  },

  async update(data) {
    try {
      const result = await window.authedClient.patch(`jsonapi/${endpoint}/${data.id}`, {
        data: this.stripDates(this.getCleanedData(data))
      });
      return result.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  async delete(id) {
    const data = {
      data: {
        type: endpoint,
        id
      }
    };

    try {
      return await window.authedClient.delete(`jsonapi/${endpoint}/${id}`, { data });
    } catch (error) {
      return handleApiError(error);
    }
  },

  /**
   * Attaches a child to a parent
   *
   * Example structure of a relationship:
   * "relationships": {
   *      "activities": {
   *          "data": [
   *              {
   *                  "type": "function_activity",
   *                  "id": "4852ae04-20f3-4945-8b1e-260df21f5713",
   *                  "meta": {
   *                      "drupal_internal__target_id": 1
   *                  }
   *              }
   *          ],
   *          "links": {
   *              "related": {
   *                  "href": "http://learn.aws-staging.pe-academy.nl/jsonapi/portfolio_function/8fd5848f-b2b2-4b50-8f14-6019047f0727/activities"
   *              },
   *              "self": {
   *                  "href": "http://learn.aws-staging.pe-academy.nl/jsonapi/portfolio_function/8fd5848f-b2b2-4b50-8f14-6019047f0727/relationships/activities"
   *              }
   *          }
   *      }
   * }
   *
   * @param {*} parentData The data node of the parent object (so without the included node, etc)
   * @param {*} relationName The node name of the relation to bind (e.g. functions)
   * @param {*} relationType The data type of the relation to bind (e.g. portfolio_function)
   * @param {*} relationId The uid of the function_activity to bind
   * @returns
   */
  async attach(parentData, relationName, relationType, relationId) {
    const data = attachRelation(parentData, relationName, relationType, relationId);
    return this.update(data);
  },

  async detach(parentData, relationName, relationIds) {
    const data = detachRelation(parentData, relationName, relationIds);
    return this.update(data);
  },

  /**
   * Removes all missing relations from the data object
   *
   * @param {*} data The data node of the object to clean {attributes: {}, relationships: {}}
   * @returns
   */
  getCleanedData(data) {
    const relationships = {};

    if (!data.relationships) return data;

    Object.keys(data.relationships).forEach((key) => {
      if (['uid', 'override_file'].includes(key)) {
        relationships[key] = data.relationships[key];
        return;
      }

      const cleanedRelationship = {
        ...data.relationships[key],
        data: data.relationships[key].data.filter((d) => d.id !== 'missing')
      };
      relationships[key] = cleanedRelationship;
    });

    return {
      ...data,
      relationships
    };
  },

  stripDates(data) {
    return {
      ...data,
      attributes: {
        ...data.attributes,
        created: undefined,
        changed: undefined
      }
    };
  }
});
