import { flow, types as t } from 'mobx-state-tree';
import { CURRICULUM_TYPE } from './constants';

const initialData = { data: [], included: [] };

const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc'
};

const yearSorter = (a, b, sortDir = SORT_DIR.ASC) => {
  if (a.year < b.year) {
    return sortDir === SORT_DIR.ASC ? -1 : 1;
  }
  if (a.year > b.year) {
    return sortDir === SORT_DIR.ASC ? 1 : -1;
  }
  if (a.created < b.created) {
    return sortDir === SORT_DIR.ASC ? -1 : 1;
  }
  if (a.created > b.created) {
    return sortDir === SORT_DIR.ASC ? 1 : -1;
  }
  return 0;
};

const yearSorterDesc = (a, b) => yearSorter(a, b, SORT_DIR.DESC);

export const CurriculumStore = t
  .model({
    isLoadingCurriculum: t.optional(t.boolean, false),

    curriculumState: t.optional(t.enumeration(['init', 'done', 'error']), 'init'),
    curriculums: t.optional(t.frozen(), {}),

    curriculum: t.optional(t.frozen(), {}),
    curriculumFunctions: t.optional(t.frozen(), []),
    curriculumFunctionActivities: t.optional(t.frozen(), []),
    curriculumLearningGoals: t.optional(t.frozen(), []),
    curriculumLearningGoalActivities: t.optional(t.frozen(), []),
    curriculumEvaluations: t.optional(t.frozen(), []),
    curriculumEvaluationCourses: t.optional(t.frozen(), []),

    curriculumFunctionExamples: t.optional(t.frozen(), []),
    curriculumLearningGoalExamples: t.optional(t.frozen(), []),
    curriculumLearningGoalActivityExamples: t.optional(t.frozen(), []),

    // all completed courses for a given year that a user can pick from
    curriculumAvailableCourseResults: t.optional(t.frozen(), [])
  })

  .actions((self) => ({
    startLoadCurriculum: () => {
      self.isLoadingCurriculum = true;
    },

    stopLoadCurriculum: () => {
      self.isLoadingCurriculum = false;
    },

    initCurriculumState: () => {
      self.error = null;
    },

    // ================================
    // Portfolio/Curriculum
    // ================================
    refreshCurrentCurriculum: () => {
      if (self.curriculum) {
        setTimeout(() => {
          self.fetchCurriculum(self.curriculum.data.id);
        }, 200);
      }
    },

    /**
     * Fetches portfolio items from different API endpoint and returns
     * the items in a different format than the JSONAPI does
     */
    fetchCurriculums: flow(function* fetchCurriculums(params) {
      // Start loading before fetching
      self.startLoadCurriculum();

      // Reset state
      self.initCurriculumState();

      // Fetch curriculums
      const result = yield self.lmsApi.curriculumApi.getAll(params);

      if (result.errors) {
        self.curriculumState = 'error';
        self.error = result.errors;
      } else {
        self.curriculums = {
          ...result,
          data: result?.portfolios?.length > 0 ? result.portfolios.sort(yearSorterDesc) : []
        };
        self.curriculumState = 'done';
      }

      // Stop loading after completion
      self.stopLoadCurriculum();

      return result;
    }),

    /**
     * Fetches a portolio item from the JSONAPI and returns
     * the data in the JSONAPI format, e.g. with attributes, relationships, included, etc.
     */
    fetchCurriculum: flow(function* fetchCurriculum(uid) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumApi.getSingle(uid);

      if (result.errors) {
        self.curriculum = initialData;
        self.curriculumState = 'error';
        self.error = result.errors;
      } else {
        self.curriculum = result;
        self.curriculumFunctions = result.included?.filter((item) => item.type === CURRICULUM_TYPE.function);
        self.curriculumFunctionActivities = result.included?.filter((item) => item.type === CURRICULUM_TYPE.functionActivity);
        self.curriculumLearningGoals = result.included?.filter((item) => item.type === CURRICULUM_TYPE.learningGoal);
        self.curriculumLearningGoalActivities = result.included?.filter((item) => item.type === CURRICULUM_TYPE.learningGoalActivity);
        self.curriculumEvaluations = result.included?.filter((item) => item.type === CURRICULUM_TYPE.learningGoalEvaluation);
        self.curriculumEvaluationCourses = result.included?.filter((item) => item.type === CURRICULUM_TYPE.courseResult);
        yield self.fetchAvailableCourseResultForCurriculum(self.curriculum?.data?.attributes?.year);
        self.curriculumState = 'done';
      }

      return result;
    }),

    createCurriculum: flow(function* createCurriculum(data) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumApi.create({
        ...data,
        is_finished: false,
        percentage_completed: 0,
        is_deleted: 0,
        current_step: 1
      });

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumState = 'done';
      }

      return result;
    }),

    updateCurriculum: flow(function* updateCurriculum(curriculum) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumApi.update(curriculum);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumState = 'done';
      }

      return result;
    }),

    unarchiveCurriculum: flow(function* unarchiveCurriculum(uid) {
      self.initCurriculumState();

      const data = {
        id: uid,
        type: 'portfolio',
        attributes: {
          is_deleted: 0
        }
      };

      return yield self.lmsApi.curriculumApi.update(data);
    }),

    deleteCurriculum: flow(function* deleteCurriculum(id) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumApi.delete(id);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumState = 'done';
      }

      // update curriculums state
      if (self.curriculums?.length > 0) {
        self.curriculums = {
          ...self.curriculums,
          data: self.curriculums.data.filter((c) => c.id !== id)
        };
      }

      return true;
    }),

    finalizeCurriculum: flow(function* finalizeCurriculum(curriculum) {
      self.initCurriculumState();

      const data = {
        id: curriculum.id,
        type: curriculum.type,
        attributes: {
          is_finished: true,
          percentage_completed: 100
        }
      };

      return yield self.lmsApi.curriculumApi.update(data);
    }),

    updateCurriculumStep: flow(function* updateCurriculumStep(curriculum, step) {
      self.initCurriculumState();

      const data = {
        id: curriculum.id,
        type: curriculum.type,
        attributes: {
          current_step: step
        }
      };

      yield self.lmsApi.curriculumApi.update(data);
    }),

    /**
     * Used when deleting a function or learning goal from a curriculum
     *
     * @param curriculum
     * @param percentage
     */
    maybeUpdateCurriculumPercentage: flow(function* maybeUpdateCurriculumPercentage(curriculum, percentage) {
      if (percentage <= curriculum.attributes.percentage_completed) {
        return;
      }

      const data = {
        id: curriculum.id,
        type: curriculum.type,
        attributes: {
          percentage_completed: percentage
        }
      };

      yield self.lmsApi.curriculumApi.update(data);
    }),

    /**
     * Used when deleting a function or learning goal from a curriculum
     *
     * @param curriculum
     */
    maybeResetStepAndPercentage: flow(function* maybeResetStepAndPercentage(curriculum) {
      const numFunctions = curriculum.relationships?.functions?.data?.length ?? 0;
      const numLearningGoals = curriculum.relationships?.learning_goals?.data?.length ?? 0;

      if (numFunctions === 0 || numLearningGoals === 0) {
        const currentStep = numFunctions === 0 ? 1 : 2;
        const percentageCompleted = numFunctions === 0 ? 0 : 25;

        const data = {
          ...curriculum,
          attributes: {
            ...curriculum.attributes,
            current_step: currentStep,
            percentage_completed: percentageCompleted
          }
        };

        return yield self.lmsApi.curriculumApi.update(data);
      }

      return curriculum;
    }),

    attachCurriculumFunctionToCurriculum: flow(function* attachCurriculumFunctionToCurriculum(curriculum, functionId) {
      return yield self.lmsApi.curriculumApi.attach(curriculum, 'functions', 'portfolio_function', functionId);
    }),

    attachCurriculumLearningGoalToCurriculum: flow(function* attachCurriculumLearningGoalToCurriculum(curriculum, functionId) {
      return yield self.lmsApi.curriculumApi.attach(curriculum, 'learning_goals', 'learning_goal', functionId);
    }),

    // ================================
    // Functions & Activities
    // ================================
    fetchCurriculumFunctionExamples: flow(function* fetchCurriculumFunctionExamples() {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumFunctionExamplesApi.getAll();

      if (result?.errors) {
        self.curriculumFunctionExamples = {};
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumFunctionExamples = result;
        self.curriculumState = 'done';
      }

      return result;
    }),

    createCurriculumFunctionWithActivity: flow(function* createCurriculumFunctionWithActivity(curriculum, data) {
      self.initCurriculumState();

      // create function for this curriculum
      const fn = yield self.createCurriculumFunction(curriculum, { name: data.functionName });

      if (fn.errors) {
        return fn;
      }
      // create activity for this function
      const activity = yield self.createCurriculumFunctionActivity(fn.data.data, {
        name: data.activityName,
        expected_result: data.activityResult
      });

      if (!activity || activity?.errors) {
        yield self.lmsApi.curriculumFunctionApi.delete(fn.data.data.id);
        return activity;
      }

      yield self.maybeUpdateCurriculumPercentage(curriculum, 25);
      return fn;
    }),

    createCurriculumFunction: flow(function* createCurriculumFunction(curriculum, data) {
      self.initCurriculumState();

      self.error = null;

      const result = yield self.lmsApi.curriculumFunctionApi.create(data);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        yield self.sleep();
        yield self.attachCurriculumFunctionToCurriculum(curriculum, result.data.data.id);
        self.curriculumState = 'done';
      }

      return result;
    }),

    updateCurriculumFunction: flow(function* updateCurriculumFunction(fn) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumFunctionApi.update(fn);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result;
      } else {
        self.curriculumState = 'done';
      }

      return result;
    }),

    createCurriculumFunctionActivity: flow(function* createCurriculumFunctionActivity(fnData, data) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumFunctionActivityApi.create(data);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        yield self.sleep();
        yield self.attachCurriculumFunctionActivityToCurriculumFunction(fnData, result.data.data.id);
        self.curriculumState = 'done';
      }

      return result;
    }),

    updateCurriculumFunctionActivity: flow(function* updateCurriculumFunctionActivity(data) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumFunctionActivityApi.update(data);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result;
      } else {
        self.curriculumState = 'done';
      }

      return result;
    }),

    deleteCurriculumFunction: flow(function* deleteCurriculumFunction(curriculum, fn) {
      self.initCurriculumState();

      // first we delete the relationship between the curriculum and the function
      // when deleting the last function, we also need to reset the current_step and percentage_completed
      const updatedCurriculum = yield self.lmsApi.curriculumApi.detach(curriculum, 'functions', fn.id);

      if (!updatedCurriculum || updatedCurriculum?.errors) {
        self.curriculumState = 'error';
        self.error = updatedCurriculum?.errors;
        return updatedCurriculum;
      }

      yield self.maybeResetStepAndPercentage(updatedCurriculum.data);

      // next delete all related function activities
      const stack = fn.activities.map((activity) => self.lmsApi.curriculumFunctionActivityApi.delete(activity.id));
      yield Promise.all(stack);

      // finally delete the function itself
      const result = yield self.lmsApi.curriculumFunctionApi.delete(fn.id);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumState = 'done';
      }

      return updatedCurriculum;
    }),

    deleteCurriculumFunctionActivities: flow(function* deleteCurriculumFunctionActivities(fn, ids) {
      self.initCurriculumState();

      // First remove the relationship between the function and the activities
      // For the user this is the real delete (any orphaned activities will be deleted by the backend)
      const fnUpdated = {
        ...fn,
        relationships: {
          ...fn.relationships,
          activities: {
            ...fn.relationships.activities,
            data: fn.relationships.activities.data.filter((a) => !ids.includes(a.id))
          }
        }
      };
      const result = yield self.updateCurriculumFunction(fnUpdated);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumFunctions = self.curriculumFunctions.filter((a) => !ids.includes(a.id));

        // Then try and delete the activities themselves so we don't have any orphaned activities
        const stack = ids.map((id) => self.lmsApi.curriculumFunctionActivityApi.delete(id));
        yield Promise.all(stack);
        self.curriculumState = 'done';
      }

      return result;
    }),

    attachCurriculumFunctionActivityToCurriculumFunction: flow(function* attachCurriculumFunctionActivityToCurriculumFunction(
      functionData,
      functionActivityId
    ) {
      return yield self.lmsApi.curriculumFunctionApi.attach(functionData, 'activities', 'function_activity', functionActivityId);
    }),

    // ================================
    // Learning Goals
    // ================================
    fetchCurriculumLearningGoalExamples: flow(function* fetchCurriculumLearningGoalExamples() {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumLearningGoalExamplesApi.getAll();

      if (result?.errors) {
        self.curriculumLearningGoalExamples = {};
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumLearningGoalExamples = result.data;
        self.curriculumState = 'done';
      }

      return result;
    }),

    fetchCurriculumLearningGoalActivityExamples: flow(function* fetchCurriculumLearningGoalActivityExamples() {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumLearningGoalActivityExamplesApi.getAll();

      if (result?.errors) {
        self.curriculumLearningGoalActivityExamples = {};
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumLearningGoalActivityExamples = result.data;
        self.curriculumState = 'done';
      }

      return result;
    }),

    createCurriculumLearningGoalWithActivity: flow(function* createCurriculumLearningGoalWithActivity(curriculum, data) {
      self.initCurriculumState();

      // create goal for this curriculum
      const goal = yield self.createCurriculumLearningGoal(curriculum, { name: data.learningGoalName });

      if (!goal || goal?.errors) {
        return goal;
      }

      // create activity for this goal
      // used a sleep here, because sometimes got a 404 error from the API, that the goal didnt exist...
      yield self.sleep();
      const activityResult = yield self.createCurriculumLearningGoalActivity(goal.data.data, {
        name: data.activityName,
        explanation: data.activityExplanation
      });

      if (!activityResult || activityResult?.errors) {
        yield self.lmsApi.curriculumLearningGoalApi.delete(goal.data.data.id);
        return activityResult;
      }

      yield self.maybeUpdateCurriculumPercentage(curriculum, 50);

      return goal;
    }),

    createCurriculumLearningGoal: flow(function* createCurriculumLearningGoal(curriculum, data) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumLearningGoalApi.create(data);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        yield self.sleep();
        yield self.attachCurriculumLearningGoalToCurriculum(curriculum, result.data.data.id);
        self.curriculumState = 'done';
      }

      return result;
    }),

    updateCurriculumLearningGoal: flow(function* updateCurriculumLearningGoal(data) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumLearningGoalApi.update(data);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result;
      } else {
        self.curriculumState = 'done';
      }

      return result;
    }),

    deleteCurriculumLearningGoal: flow(function* deleteCurriculumLearningGoal(curriculum, goal) {
      self.initCurriculumState();

      // first delete relationship between curriculum and learning goal
      // when deleting the last learning goal, we also need to reset the current_step and percentage_completed
      const updatedCurriculum = yield self.lmsApi.curriculumApi.detach(curriculum, 'learning_goals', goal.id);

      if (!updatedCurriculum || updatedCurriculum?.errors) {
        self.curriculumState = 'error';
        self.error = updatedCurriculum?.errors;
        return updatedCurriculum;
      }

      yield self.maybeResetStepAndPercentage(updatedCurriculum.data);

      // delete all related activities
      const activityIds = goal.relationships?.activities?.data?.map((a) => a.id);
      const goalWithoutActivities = yield self.deleteCurriculumLearningGoalActivities(goal, activityIds);
      if (!goalWithoutActivities || goalWithoutActivities?.errors) {
        return goalWithoutActivities;
      }

      // delete all related evaluations
      const evaluationIds = goal.relationships?.evaluations?.data?.map((e) => e.id);
      const goalWithoutEvaluations = yield self.deleteCurriculumEvaluations(goalWithoutActivities.data, evaluationIds);
      if (!goalWithoutEvaluations || goalWithoutEvaluations?.errors) {
        return goalWithoutEvaluations;
      }

      // delete the learning goal itself
      const result = yield self.lmsApi.curriculumLearningGoalApi.delete(goal.id);
      if (!result || result?.errors) {
        self.curriculumState = 'error';
        self.error = updatedCurriculum?.errors;
      } else {
        self.curriculumState = 'done';
      }

      return updatedCurriculum;
    }),

    createCurriculumLearningGoalActivity: flow(function* createCurriculumLearningGoalActivity(goal, data) {
      self.initCurriculumState();

      const activity = yield self.lmsApi.curriculumLearningGoalActivityApi.create(data);

      if (activity?.errors) {
        self.curriculumState = 'error';
        self.error = activity?.errors;
        return activity;
      }

      yield self.sleep();
      const goalWithActivity = yield self.attachCurriculumLearningGoalActivityToLearningGoal(goal, activity.data.data.id);

      if (goalWithActivity?.errors) {
        self.curriculumState = 'error';
        self.error = goalWithActivity?.errors;
        yield self.lmsApi.curriculumLearningGoalActivityApi.delete(activity.data.data.id);
        return goalWithActivity;
      }

      self.curriculumState = 'done';

      return { activity: activity.data.data, goal: goalWithActivity.data };
    }),

    updateCurriculumLearningGoalActivity: flow(function* updateCurriculumLearningGoalActivity(data) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumLearningGoalActivityApi.update(data);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result;
      } else {
        self.curriculumState = 'done';
      }

      return result;
    }),

    deleteCurriculumLearningGoalActivities: flow(function* deleteCurriculumLearningGoalActivities(goal, ids) {
      self.initCurriculumState();

      // First remove the relationship between the learning goal and the activities
      const result = yield self.lmsApi.curriculumLearningGoalApi.detach(goal, 'activities', ids);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        // Then try and delete the activities themselves so we don't have any orphaned activities
        const stack = ids.map((id) => self.lmsApi.curriculumLearningGoalActivityApi.delete(id));
        yield Promise.all(stack);

        self.curriculumState = 'done';
      }

      return result;
    }),

    attachCurriculumLearningGoalActivityToLearningGoal: flow(function* attachCurriculumLearningGoalActivityToLearningGoal(
      goal,
      activityId
    ) {
      return yield self.lmsApi.curriculumLearningGoalApi.attach(goal, 'activities', 'learning_goal_activity', activityId);
    }),

    attachCurriculumEvaluationToLearningGoal: flow(function* attachCurriculumEvaluationToLearningGoal(goal, evaluationId) {
      return yield self.lmsApi.curriculumLearningGoalApi.attach(goal, 'evaluations', 'learning_goal_evaluation', evaluationId);
    }),

    // ================================
    // Evaluations & activities
    // ================================
    createOrUpdateCurriculumEvaluation: flow(function* createOrUpdateCurriculumEvaluation(goal, data) {
      if (data.id) {
        return yield self.updateCurriculumEvaluation(data);
      }

      return yield self.createCurriculumEvaluation(goal, data);
    }),

    createCurriculumEvaluation: flow(function* createCurriculumEvaluation(goal, data) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumLearningGoalEvaluationApi.create(data);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        yield self.sleep();
        yield self.attachCurriculumEvaluationToLearningGoal(goal, result.data.data.id);
        self.curriculumState = 'done';
      }

      return result;
    }),

    updateCurriculumEvaluation: flow(function* updateCurriculumEvaluation(data) {
      self.initCurriculumState();

      const result = yield self.lmsApi.curriculumLearningGoalEvaluationApi.update(data);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        yield self.maybeUpdateCurriculumPercentage(self.curriculum.data, 75);

        self.curriculumState = 'done';
      }

      return result;
    }),

    deleteCurriculumEvaluations: flow(function* deleteCurriculumEvaluations(goal, ids) {
      self.initCurriculumState();

      // First remove the relationship between the evaluation and the learning goal
      const result = yield self.lmsApi.curriculumLearningGoalApi.detach(goal, 'evaluations', ids);

      if (result?.errors) {
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        // Then try and delete the evaluations themselves so we don't have any orphaned evaluations
        const stack = ids.map((id) => self.lmsApi.curriculumLearningGoalEvaluationApi.delete(id));
        yield Promise.all(stack);
        self.curriculumState = 'done';
      }

      return result;
    }),

    attachCurriculumCourseResultToEvaluation: flow(function* attachCurriculumCourseResultToEvaluation(evaluation, courseId) {
      return yield self.lmsApi.curriculumLearningGoalEvaluationApi.attach(evaluation, 'courses', 'course_result', courseId);
    }),

    // ================================
    // CourseResults
    // ================================
    fetchAvailableCourseResultForCurriculum: flow(function* fetchAvailableCourseResultForCurriculum(year) {
      self.initCurriculumState();

      if (!year) {
        return;
      }

      const timestampFrom = new Date(`${year}-01-01T00:00:00+00:00`).getTime() / 1000;
      const timestampTill = new Date(`${year + 1}-01-31T23:59:59+00:00`).getTime() / 1000;

      const params = {
        sort: 'name',
        filter: {
          completedDate: {
            condition: {
              value: [timestampFrom, timestampTill],
              path: 'certificate',
              operator: 'BETWEEN'
            }
          }
        }
      };

      const result = yield self.lmsApi.fetchCourseResults(self.login.uuid, params);

      if (result?.errors) {
        self.curriculumAvailableCourseResults = {};
        self.curriculumState = 'error';
        self.error = result?.errors;
      } else {
        self.curriculumAvailableCourseResults = result.data.data;
        self.curriculumState = 'done';
      }
    })
  }));
