import { rawHTML } from 'helpers/Helpers';
import { types as t } from 'mobx-state-tree';

const drupalBaseUrl = process.env.REACT_APP_JSONAPI;

export const Author = t
  .model('Author', {
    id: t.identifierNumber,
    uuid: t.string,
    title: t.string,
    body: t.maybeNull(t.string),
    body_summary: t.maybeNull(t.string),
    body_value: t.maybeNull(t.string),
    affiliation: t.maybeNull(t.string),
    first_name: t.maybeNull(t.string),
    last_name: t.maybeNull(t.string),
    initials: t.maybeNull(t.string),
    work_position: t.maybeNull(t.string),
    titles: t.maybeNull(t.string),
    sex: t.maybeNull(t.string),
    photo_id: t.maybeNull(t.number),
    photo_uri: t.maybeNull(t.string)
  })
  .views((self) => ({
    get fullName() {
      const { titles, first_name: firstName, last_name: lastName, affiliation } = self;
      return [titles, firstName, lastName, affiliation]
        .filter(Boolean)
        .map((el) => el.trim())
        .join(' ');
    },
    get img() {
      return self.photo_uri && `${drupalBaseUrl}${self.photo_uri}`;
    },
    get bio() {
      return self.body_value && rawHTML(self.body_value);
    }
  }));

export const Authors = t
  .model('Authors', {
    authorList: t.array(Author),
    searchAuthorName: t.maybeNull(t.string)
  })
  .views((self) => ({
    get list() {
      return self.searchAuthorName ? self.filteredListByAuthorName : self.authorList;
    },
    get filteredListByAuthorName() {
      const regex = new RegExp(self.searchAuthorName, 'i');
      return self.authorList.filter((author) =>
        `${author.titles || ''} ${author.first_name || ''} ${author.last_name || ''} ${author.affiliation || ''}`.match(regex)
      );
    },
    get sortedByFullName() {
      return self.authorList.slice().sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
    },
    getAuthorByUuid(uuid) {
      return self.authorList.find((author) => author.uuid === uuid);
    },
    getAuthorById(id) {
      return self.authorList.find((author) => author.id === id);
    }
  }))
  .actions((self) => ({
    searchByName(name) {
      self.searchAuthorName = name;
    }
  }));
