import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
/* MUI */
import { Button } from '@mui/material';

const BtnPreviewIntroduction = ({ handleClickDialog, getValues }) => (
  <Button
    variant="contained"
    disableElevation
    color="primary"
    size="small"
    sx={{ borderRadius: '20px' }}
    onClick={() => handleClickDialog(getValues())}
  >
    Preview
  </Button>
);

BtnPreviewIntroduction.propTypes = {
  handleClickDialog: PropTypes.func,
  getValues: PropTypes.func
};

export default observer(BtnPreviewIntroduction);
