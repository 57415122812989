const BORDER_COLOR = '#EEE';
const BORDER = `1px solid ${BORDER_COLOR}`;
// const TABLE_BODY_TEXT_COLOR = '#A3AED0';
const BACKGROUND_GRAY = 'rgba(43, 54, 116, 0.1)';
const BACKGROUND_GREEN = '#80B4A3';
const HEADING_COLOR = '#2B3674';

export const getLmsTheme = (theme) => ({
  palette: {
    background: {
      gray: BACKGROUND_GRAY,
      green: BACKGROUND_GREEN
    }
  },
  shadows: [
    'none',
    '0px 11.168px 24.8178px rgb(112 144 176 / 12%)',
    '0px 11.168px 24.8178px rgb(112 144 176 / 12%)',
    ...theme.shadows.slice(3)
  ],
  typography: {
    h1: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.75,
      color: HEADING_COLOR
    },
    h2: {
      fontSize: '1.375rem',
      fontWeight: 700,
      lineHeight: 1.16,
      color: HEADING_COLOR
    },

    count: {
      fontSize: '30px',
      fontWeight: 700
    }
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: theme.spacing(2),
            '& .MuiOutlinedInput-input': {
              paddingBlock: theme.spacing(1.5)
            },
            '& fieldset': {
              borderColor: BORDER_COLOR
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      variants: [
        {
          props: { variant: 'customAutocomplete' },
          style: {
            '&.Mui-focused': {
              label: {
                color: theme.palette.primary.main
              }
            },
            '& .MuiInputBase-root': {
              padding: 0
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: theme.spacing(2),
              position: 'relative',
              width: '100%',
              '& .MuiOutlinedInput-input.MuiAutocomplete-input': {
                padding: theme.spacing(1.5)
              },
              '&:focus-within': {
                border: 'none'
              },
              '& fieldset': {
                border: BORDER
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main
              },
              '&.Mui-focused fieldset': {
                border: BORDER,
                borderColor: theme.palette.primary.main
              }
            }
          }
        }
      ]
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: `0 ${theme.spacing(1)}`,
          padding: `0 ${theme.spacing(2)}`
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: theme.palette.primaryBlue.main,
          '& .MuiTableCell-root': {
            border: 'none'
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          // color: TABLE_BODY_TEXT_COLOR,
          '& .MuiTableCell-root': {
            border: BORDER,
            borderLeft: 'none',
            borderRight: 'none'
          },
          '& .MuiTableCell-root:first-of-type': {
            borderLeft: BORDER,
            borderTopLeftRadius: theme.spacing(4),
            borderBottomLeftRadius: theme.spacing(4)
          },
          '& .MuiTableCell-root:last-of-type': {
            borderRight: BORDER,
            borderTopRightRadius: theme.spacing(4),
            borderBottomRightRadius: theme.spacing(4)
          }
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          position: 'absolute',
          bottom: -60,
          right: 0,
          '& .MuiTableCell-root': {
            border: 'none'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(1)
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& a': {
            textDecoration: 'none'
          },
          '& .ButtonNavigation': {
            color: HEADING_COLOR,
            borderRadius: theme.spacing(2),
            border: BORDER,
            justifyContent: 'left',
            marginTop: theme.spacing(1),
            '&:last-of-type': {
              marginBottom: 0
            },
            transition: 'all 0.5s',
            '& .MuiTypography-root': {
              fontWeight: 700,
              fontSize: '14px'
            },
            '& svg': {
              color: HEADING_COLOR
            }
          },
          '& .ButtonNavigation-active .ButtonNavigation': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            borderColor: theme.palette.primary.main
          },
          '& .ButtonNavigation-active .ButtonNavigation svg': {
            color: 'white'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(2)
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&.Mui-selected': {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  }
});
