import { useContext } from 'react';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';

import { Chip, Tooltip } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import { translatePoints } from 'helpers/Helpers';

const TotalSubPoints = () => {
  const store = useContext(Store);
  const { t } = useTranslation();

  // NO ACTIVE SUBSCRIPTIONS return fragment
  // eslint-disable-next-line no-extra-boolean-cast
  if (!store?.subscriptions?.subscriptions?.length > 0) return null;

  // ACTIVE SUBSCRIPTIONS return total points
  const userPoints = `${store.subscriptions?.totalSubscriptionPoints(store?.baseProduct?.nid)}`;
  const accreditation = store?.baseProduct?.packages.find((subPackage) => subPackage.nid === store?.selectedPackageId);
  const accrUnit = accreditation?.accreditation?.accr_unit;

  let toolTipText = accrUnit !== 'punt' ? t('headerbar.earnedHours', 'Training hours') : t('headerbar.earnedPoints', 'Earned points');
  toolTipText = store?.isEwiseUser ? t('headerbar.unlimited', 'Unlimited') : toolTipText;

  return (
    <Tooltip title={toolTipText} arrow>
      <Chip
        className="onboarding-points"
        sx={{
          background: '#F4F7FE',
          [`.MuiChip-label`]: { width: '100%', paddingLeft: '8px', paddingRight: '8px' }
        }}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                background: 'white',
                borderRadius: '49px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '21px',
                height: '21px'
              }}
            >
              <StarsIcon sx={{ width: '13px', height: '13px' }} color="primary" />
            </div>
            <div
              style={{
                color: '#2B3674',
                fontWeight: '700',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: ' hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {userPoints ? translatePoints(userPoints, t) : '0'}
            </div>
          </div>
        }
      />
    </Tooltip>
  );
};

export default observer(TotalSubPoints);
